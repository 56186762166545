import Axios from 'axios'
const options = {
    headers: { 'Content-Type': 'application/json' },
}
class BaseApiService {
    baseUrl =
        process.env.VUE_APP_NODE_ENV === 'development' ?
            process.env.VUE_APP_DEV_BASE_URL :
            'https://admin.lay-bare.com/api'

    resource

    constructor(resource) {
        if (!resource) throw new Error('Resource is not provided')
        this.resource = resource
    }

    getUrl(id = '') {
        let url = `${this.baseUrl}/${this.resource}`;

        if (id) {
            url = `${url}/${id}`;
        }
        return url;
    }

    handleErrors(err) {
        // Note: here you may want to add your errors handling
        console.log({ message: err })
    }
}

class ReadOnlyApiService extends BaseApiService {
    constructor(resource) {
        super(resource)
    }

    async fetch(config = {}) {
        try {
            const response = await Axios.post(this.getUrl(), config, options)
            return await response
        } catch (err) {
            this.handleErrors(err)
            let error = {
                type: 'error',
                title: 'Network Error',
                message: 'Sorry, something went wrong\nSeems like we have an internal server error. Please try again later or report this issue.',
            }
            return { error }
        }
    }
    async get(config = {}, id = '') {
        try {
            const response = await Axios.get(this.getUrl(id), { params: config })
            let { data } = response;
            return await data
        } catch (err) {
            this.handleErrors(err)
        }
    }
}
//FOR DEVELOPMENT

//FOR PRODUCTION
class BranchesApiService extends ReadOnlyApiService {
    constructor() {
        super('branch/all')
    }
}
class ServicesApiService extends ReadOnlyApiService {
    constructor() {
        super('service/')
    }
}

class PackagesApiService extends ReadOnlyApiService {
    constructor() {
        super('package/')
    }
}

class BusinessUnitApiService extends ReadOnlyApiService {
    constructor() {
        super('business-unit/all')
    }
}

class RegionsApiService extends ReadOnlyApiService {
    constructor() {
        super('regions/')
    }
}

class ProductsApiService extends ReadOnlyApiService {
    constructor() {
        super('product/')
    }
}

class ProductCategoriesApiService extends ReadOnlyApiService {
    constructor() {
        super('product/categories')
    }
}

class UUIDGeneration extends ReadOnlyApiService {
    constructor() {
        super('referral/generated-uuid')
    }
}

export const $api = {
    branches: new BranchesApiService(),
    services: new ServicesApiService(),
    packages: new PackagesApiService(),
    businessUnit: new BusinessUnitApiService(),
    regions: new RegionsApiService(),
    products: new ProductsApiService(),
    productCategories: new ProductCategoriesApiService(),
    generateUuid: new UUIDGeneration()
}