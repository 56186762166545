import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuemeta from 'vue-meta'
Vue.use(VueRouter)
Vue.use(Vuemeta)
const routes = [
    {
        path: '',
        name: 'MainLayout',
        component: () => import ('../components/Layout.vue'),
        children: [
            { path: '/', name: 'landing', component: () => import ('../views/LandingPage.vue') },
            {
                path: '/laybare',
                name: 'Lay Bare Landing',
                component: () => import ('../components/layouts/LaybareLayout.vue'),
                redirect: '/laybare/',
                children: [
                    {
                        path: '/',
                        name: 'home',
                        component: () => import ('../views/laybare/LaybareHome.vue'),
                        meta: { brand: 'Lay Bare' }
                    },
                    {
                        path: '/laybare/branches',
                        name: 'Lay Bare Branches',
                        component: () => import ('../views/laybare/LaybareLocation.vue'),
                        meta: { brand: 'Lay Bare', title: 'Branches' }
                    },
                    {
                        path: '/laybare/services',
                        name: 'Lay Bare Services',
                        component: () => import ('../views/laybare/LaybareServices.vue'),
                        meta: { brand: 'Lay Bare', title: 'Services' }
                    },
                    {
                        path: '/laybare/products',
                        name: 'Lay Bare Products',
                        component: () => import ('../views/laybare/LaybareProducts.vue'),
                        meta: { brand: 'Lay Bare', title: 'Products' }
                    },
                    {
                        path: '/laybare/egift',
                        name: 'Lay Bare E-Gift',
                        component: () => import ('../views/laybare/LaybareEgift.vue'),
                        meta: { brand: 'Lay Bare', title: 'E-Gift' }
                    },
                    {
                        path: '/laybare/safety-standards',
                        name: 'Safety Standards',
                        component: () => import ('../views/footer-contents/lay-bare-footer/SafetyStandards.vue'),
                        meta: { brand: 'Lay Bare', title: 'Safety Standards' }
                    },
                    {
                        path: '/laybare/plc-perks',
                        name: 'PLC Perks',
                        component: () => import ('../views/footer-contents/lay-bare-footer/PLCPerks.vue'),
                        meta: { brand: 'Lay Bare', title: 'PLC Perks' }
                    },
                    {
                        path: '/laybare/faqs',
                        name: 'FAQ',
                        component: () => import ('../views/footer-contents/lay-bare-footer/FAQs.vue'),
                        meta: { brand: 'Lay Bare', title: 'FAQs' }
                    },
                    {
                        path: '/laybare/promos',
                        name: 'Promos',
                        component: () => import ('../views/footer-contents/lay-bare-footer/Promos.vue'),
                        meta: { brand: 'Lay Bare', title: 'Promos' }
                    },
                    {
                        path: '/laybare/blogs',
                        name: 'Blogs',
                        component: () => import ('../views/footer-contents/lay-bare-footer/Blogs.vue'),
                        meta: { brand: 'Lay Bare', title: 'Blogs' }
                    },
                    {
                        path: '/laybare/about-us',
                        name: 'About Us',
                        component: () => import ('../views/footer-contents/lay-bare-footer/AboutUs.vue'),
                        meta: { brand: 'Lay Bare', title: 'About Us' }
                    },
                    {
                        path: '/laybare/careers',
                        name: 'Careers',
                        component: () => import ('../views/footer-contents/lay-bare-footer/Careers.vue'),
                        meta: { brand: 'Lay Bare', title: 'Careers' }
                    },
                    {
                        path: '/laybare/legal-terms',
                        name: 'Legal Terms',
                        component: () => import ('../views/footer-contents/lay-bare-footer/PrivacyPolicy.vue'),
                        meta: { brand: 'Lay Bare', title: 'Legal Terms' }
                    },
                    {
                        path: '/laybare/contact-us',
                        name: 'Contact Us',
                        component: () => import ('../views/footer-contents/lay-bare-footer/ContactUs.vue'),
                        meta: { brand: 'Lay Bare', title: 'Contact Us' }
                    }
                ]
            },
            {
                path: '/laybareplus',
                name: 'Lay Bare Plus Landing',
                component: () => import ('../components/layouts/LaybarePlusLayout.vue'),
                redirect: '/laybareplus/',
                children: [
                    {
                        path: '/',
                        name: 'Lay Bare Plus Home',
                        component: () => import ('../views/laybareplus/LaybarePlusHome.vue'),
                        meta: { brand: 'Lay Bare Plus' }
                    },
                    {
                        path: '/laybareplus/branches',
                        name: 'Lay Bare Plus Branches',
                        component: () => import ('../views/laybareplus/LaybarePlusLocation.vue'),
                        meta: { brand: 'Lay Bare Plus', title: 'Branches' }
                    },
                    {
                        path: '/laybareplus/services',
                        name: 'Lay Bare Plus Services',
                        component: () => import ('../views/laybareplus/LaybarePlusServices.vue'),
                        meta: { brand: 'Lay Bare Plus', title: 'Services' }
                    },
                    {
                        path: '/laybareplus/products',
                        name: 'Lay Bare Plus Products',
                        component: () => import ('../views/laybareplus/LaybarePlusProducts.vue'),
                        meta: { brand: 'Lay Bare Plus', title: 'Products' }
                    },
                    {
                        path: '/laybareplus/egift',
                        name: 'Lay Bare Plus E-Gift',
                        component: () => import ('../views/laybareplus/LaybarePlusEgift.vue'),
                        meta: { brand: 'Lay Bare Plus', title: 'E-Gift' }
                    },
                    {
                        path: '/laybareplus/plc-perks',
                        name: 'Lay Bare Plus PLC Perks',
                        component: () => import ('../views/footer-contents/lay-bare-plus-footer/LaybarePlusPLCPerks.vue'),
                        meta: { brand: 'Lay Bare Plus', title: 'PLC Perks' }
                    },
                    {
                        path: '/laybareplus/promos',
                        name: 'Lay Bare Plus Promos',
                        component: () => import ('../views/footer-contents/lay-bare-plus-footer/LaybarePlusPromos.vue'),
                        meta: { brand: 'Lay Bare Plus', title: 'Promos' }
                    },
                    {
                        path: '/laybareplus/blogs',
                        name: 'Lay Bare Plus Blogs',
                        component: () => import ('../views/footer-contents/lay-bare-plus-footer/LaybarePlusBlogs.vue'),
                        meta: { brand: 'Lay Bare Plus', title: 'Blogs' }
                    },
                    {
                        path: '/laybareplus/about-us',
                        name: 'Lay Bare Plus About Us',
                        component: () => import ('../views/footer-contents/lay-bare-plus-footer/LaybarePlusAbout.vue'),
                        meta: { brand: 'Lay Bare Plus', title: 'About Us' }
                    },
                    {
                        path: '/laybareplus/careers',
                        name: 'Lay Bare Careers',
                        component: () => import ('../views/footer-contents/lay-bare-plus-footer/LaybarePlusCareers.vue'),
                        meta: { brand: 'Lay Bare Plus', title: 'Careers' }
                    },
                    {
                        path: '/laybareplus/faqs',
                        name: 'Lay Bare Plus FAQ',
                        component: () => import ('../views/footer-contents/lay-bare-plus-footer/LaybarePlusFAQs.vue'),
                        meta: { brand: 'Lay Bare Plus', title: 'FAQs' }
                    },
                    {
                        path: '/laybareplus/legal-terms',
                        name: 'Lay Bare Plus Legal Terms',
                        component: () => import ('../views/footer-contents/lay-bare-plus-footer/LaybarePlusLegal.vue'),
                        meta: { brand: 'Lay Bare Plus', title: 'Legal Terms' }
                    },
                    {
                        path: '/laybareplus/contact-us',
                        name: 'Lay Bare Plus Contact Us',
                        component: () => import ('../views/footer-contents/lay-bare-plus-footer/LaybarePlusContactUs.vue'),
                        meta: { brand: 'Lay Bare Plus', title: 'Contact Us' }
                    },
                    {
                        path: '/laybareplus/safety-standards',
                        name: 'Lay Bare Plus Safety Standards',
                        component: () => import ('../views/footer-contents/lay-bare-plus-footer/LaybarePlusSafety.vue'),
                        meta: { brand: 'Lay Bare Plus', title: 'Safety Standards' }
                    }
                ]
            },
            {
                path: '/passionails',
                name: 'Passionails Landing',
                component: () => import ('../components/layouts/PassionailsLayout.vue'),
                redirect: '/passionails/',
                children: [
                    {
                        path: '/',
                        name: 'Passionails Home',
                        component: () => import ('../views/passionails/PassionailsHome.vue'),
                        meta: { brand: 'Passionails' }
                    },
                    {
                        path: '/passionails/branches',
                        name: 'Passionails Branches',
                        component: () => import ('../views/passionails/PassionailsLocation.vue'),
                        meta: { brand: 'Passionails', title: 'Branches' }
                    },
                    {
                        path: '/passionails/services',
                        name: 'Passionails Services',
                        component: () => import ('../views/passionails/PassionailsServices.vue'),
                        meta: { brand: 'Passionails', title: 'Services' }
                    },
                    {
                        path: '/passionails/products',
                        name: 'Passionails Products',
                        component: () => import ('../views/passionails/PassionailsProducts.vue'),
                        meta: { brand: 'Passionails', title: 'Products' }
                    },
                    {
                        path: '/passionails/egift',
                        name: 'Passionails E-Gift',
                        component: () => import ('../views/passionails/PassionailsEgift.vue'),
                        meta: { brand: 'Passionails', title: 'E-Gift' }
                    },
                    {
                        path: '/passionails/plc-perks',
                        name: 'Passionails PLC Perks',
                        component: () => import ('../views/footer-contents/passionails-footer/PassionailsPLCPerks.vue'),
                        meta: { brand: 'Passionails', title: 'PLC Perks' }
                    },
                    {
                        path: '/passionails/promos',
                        name: 'Passionails Promos',
                        component: () => import ('../views/footer-contents/passionails-footer/PassionailsPromo.vue'),
                        meta: { brand: 'Passionails', title: 'Promos' }
                    },
                    {
                        path: '/passionails/blogs',
                        name: 'Passionails Blogs',
                        component: () => import ('../views/footer-contents/passionails-footer/PassionailsBlogs.vue'),
                        meta: { brand: 'Passionails', title: 'Blogs' }
                    },
                    {
                        path: '/passionails/about-us',
                        name: 'Passionails About Us',
                        component: () => import ('../views/footer-contents/passionails-footer/PassionailsAbout.vue'),
                        meta: { brand: 'Passionails', title: 'About Us' }
                    },
                    {
                        path: '/passionails/careers',
                        name: 'Passionails Careers',
                        component: () => import ('../views/footer-contents/passionails-footer/PassionailsCareer.vue'),
                        meta: { brand: 'Passionails', title: 'Careers' }
                    },
                    {
                        path: '/passionails/faqs',
                        name: 'Passionails FAQ',
                        component: () => import ('../views/footer-contents/passionails-footer/PassionailsFAQ.vue'),
                        meta: { brand: 'Passionails', title: 'FAQs' }
                    },
                    {
                        path: '/passionails/legal-terms',
                        name: 'Passionails Legal Terms',
                        component: () => import ('../views/footer-contents/passionails-footer/PassionailsLegal.vue'),
                        meta: { brand: 'Passionails', title: 'Legal Terms' }
                    },
                    {
                        path: '/passionails/contact-us',
                        name: 'Passionails Contact Us',
                        component: () => import ('../views/footer-contents/passionails-footer/PassionailsContact.vue'),
                        meta: { brand: 'Passionails', title: 'Contact Us' }
                    },
                    {
                        path: '/passionails/safety-standards',
                        name: 'Passionails Safety Standards',
                        component: () => import ('../views/footer-contents/passionails-footer/PassionailsSafety.vue'),
                        meta: { brand: 'Passionails', title: 'Safety Standards' }
                    },
                ]
            },
            {
                path: '/lavish-lashes',
                name: 'Lavish Lashes',
                component: () => import ('../components/layouts/LavishLashesLayout.vue'),
                redirect: '/lavish-lashes/',
                children: [
                    {
                        path: '/',
                        name: 'Lavish Lashes Home',
                        component: () => import ('../views/lavishlashes/LavishHome.vue'),
                        meta: {brand: 'Lavish Lashes Studio'}
                    },
                    {
                        path: '/lavish-lashes/branches',
                        name: 'Lavish Lashes Branches',
                        component: () => import ('../views/lavishlashes/LavishLocation.vue'),
                        meta: { brand: 'Lavish Lashes Studio', title: 'Branches' }
                    },
                    {
                        path: '/lavish-lashes/services',
                        name: 'Lavish Lashes Services',
                        component: () => import ('../views/lavishlashes/LavishServices.vue'),
                        meta: { brand: 'Lavish Lashes Studio', title: 'Services' }
                    },
                    {
                        path: '/lavish-lashes/FAQs',
                        name: 'Lavish Lashes FAQs',
                        component: () => import ('../views/lavishlashes/LavishFAQs.vue'),
                        meta: { brand: 'Lavish Lashes Studio', title: 'FAQs' }
                    },
                    {
                        path: '/lavish-lashes/special-offers',
                        name: 'Lavish Lashes Special Offers',
                        component: () => import ('../views/lavishlashes/LavishSpecialOffers.vue'),
                        meta: { brand: 'Lavish Lashes Studio', title: 'Special Offers' }
                    },
                    {
                        path: '/lavish-lashes/products',
                        name: 'Lavish Lashes Products',
                        component: () => import ('../views/lavishlashes/LavishProducts.vue'),
                        meta: { brand: 'Lavish Lashes Studio', title: 'Products' }
                    },
                    {
                        path: '/lavish-lashes/egift',
                        name: 'Lavish Lashes E-Gift',
                        component: () => import ('../views/lavishlashes/LavishEgift.vue'),
                        meta: { brand: 'Lavish Lashes', title: 'E-Gift' }
                    }
                ]
            },
            {
                path: '/app',
                name: 'App',
                params: true,
                component: () => import ('../components/layouts/AppPageLayout.vue'),
                redirect: '/app/download',
                children: [
                    {
                        path: '/app/download',
                        name: 'Download',
                        component: () => import ('../components/DownloadTab.vue'),
                        meta: { brand: 'Lay Bare', title: 'Download' }
                    },
                    {
                        path: '/app/referral',
                        name: 'Referral',
                        component: () => import ('../components/ReferralTab.vue'),
                        meta: { brand: 'Lay Bare', title: 'Referral' }
                    }
                ]
            },
            {
                path: '/blog/:pname',
                name: 'Blog',
                params: true,
                component: () => import ('../views/Blog.vue'),
                meta: { brand: 'Lay Bare', title: 'Blog' }
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }

        if (to.hash) {
            return { selector: to.hash, behavior: 'smooth' };
        }

        document.getElementById('app').scrollIntoView();
    },
    routes
})

export default router