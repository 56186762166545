import Vue from 'vue'
import { $api } from '@/services/api.service'

Vue.mixin({
    computed: {
        $api: () => $api,
        $layBareId: () => 1,
        $layBarePlusId: () => 3,
        $passionailsId: () => 4,
        $lavishLashesId: () => 6,
    },

    methods: {
        $generateUUID: () => {
            let timestamp = new Date().getTime();//Timestamp
            let microseconds = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16;//random number between 0 and 16
                if(timestamp > 0){//Use timestamp until depleted
                    r = (timestamp + r)%16 | 0;
                    timestamp = Math.floor(timestamp/16);
                } else {//Use microseconds since page-load if supported
                    r = (microseconds + r)%16 | 0;
                    microseconds = Math.floor(microseconds/16);
                }
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
        }
    }
})