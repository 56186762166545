<template>
  <div id="app">
    <Layout />
  </div>
</template>
<script>
import Layout from "../src/components/Layout.vue";
export default {
  metaInfo: {
      title: "Lay Bare",
      titleTemplate: "%s",
      htmlAttrs: {
        lang: "en-US",
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            "Lay Bare is the premier waxing expert in the Philippines. We offer waxing services from brow-to-toe, using only all-natural ingredients.",
        },
        { name: "thumbnail", content: "https://lay-bare.com/logo3.png" },
        { property: "og:title", content: "Lay Bare Waxing Salon" },
        { property: "og:site_name", content: "Lay Bare" },
        { property: "og:url", content: "https://lay-bare.com/" },
        { property: "og:type", content: "website" },
        {
          property: "og:description",
          content:
            "Lay Bare is the premier waxing expert in the Philippines. We offer waxing services from brow-to-toe, using only all-natural ingredients.",
        },
        { property: "og:image", content: "https://lay-bare.com/logo3.png" },

        { name: "twitter:card", content: "summary" },
        { name: "twitter:site", content: "@Lay_Bare" },

        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { name: "robots", content: "index,follow" },
      ]
  },
  
  components: {
    Layout,
  },

  watch: {
    $route: {
        immediate: true,
        handler(to) {
          if (to.meta.title === undefined) return;
 
          document.title = to.meta.title ? `${to.meta.title} | ${to.meta.brand}` : to.meta.brand;
        }
    },
  }
};
</script>

<style>
* {
  box-sizing: border-box;
}
html,
body {
  overflow-x: hidden;
  background: #fef7ef !important;
  font-family: "Century Gothic", sans-serif !important;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  display: none;
}

textarea:focus,
input:focus,
a:focus {
  outline: none;
}

.overlay-color {
  background: rgba(0, 0, 0, 0.1);
  height: 100%;
}
.custom-max-w {
  max-width: 62rem;
}
</style>
