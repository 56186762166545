const state = {}

const getters = {}

const mutations = {}

const actions = {
    async getUuid() {
        const uuid = await this.$api.generateUuid.fetch();

        return uuid.data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}