const state = {
    products: [],
    categories: []
}

const getters = {
    allProduct: state => state.products,
    allCategory: state => state.categories
}

const mutations = {
    setProducts: (state, payload) => state.products = payload,
    setCategories: (state, payload) => {
        payload = payload.filter(category => category.is_displayed === 1);
        state.categories = payload
    }
}

const actions = {
    async getProducts({ commit }, config) {
        const products = await this.$api.products.fetch(config);

        const { data } = products;

        commit('setProducts', data.data);
    },

    async getProductCategories({commit}, config) {
        const categories = await this.$api.productCategories.fetch(config);
        const { data } = categories;
        commit('setCategories', data.data);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}