import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Store from 'vuex'
import './assets/css/tailwind.css'
import './assets/css/app.css'
import './assets/css/montserrat.css'
import './assets/css/poppins.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import 'slick-carousel/slick/slick.css'
import Vuelidate from 'vuelidate'
import '@/plugins/mixins'
import store from './store'
import VueFacebookPixel from 'vue-facebook-pixel'
import VueGtag from 'vue-gtag'

Vue.use(
    VueGtag, {
        appName: 'Lay Bare Website',
        pageTrackerScreenviewEnabled: true,
        config: { id: 'G-P0BT48KRH6' },
    },
    router
)

Vue.use(VueFacebookPixel, { router, debug: true })
Vue.use(Vuelidate)
Vue.use(Store)
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')