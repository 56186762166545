const state = {
    isDialogShow: false,
}

const getters = {
    dialogState: (state) => state.isDialogShow,
}

const mutations = {}

const actions = {
    showAnniversaryDialog({ state }, bool) {
        state.isDialogShow = bool
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}