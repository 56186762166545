const state = {
    services: [],
    packages: [],
    businessUnit: [],
    femaleServices: [],
    maleServices: [],
    waxingServices: [],
    lighteningServices: [],
    lbMaleServicePageCount: 1,
    lbFemaleServicePageCount: 1,
    lbPackagePageCount: 1,
    lbpWaxingPageCount: 1,
    lbpLighteningPageCount: 1,
    lbpScrubPageCount: 1,
    pnServicePageCount: 1
}

const getters = {
    allService: (state) => state.services,
    allPackage: (state) => state.packages,
    allBusinessUnit: (state) => state.businessUnit,
    allFemaleService: (state) => state.femaleServices,
    allMaleService: (state) => state.maleServices,
    allWaxingService: (state) => state.waxingServices,
    allLighteningService: (state) => state.lighteningServices,
    lbMaleServicePageCount: (state) => state.lbMaleServicePageCount,
    lbFemaleServicePageCount: (state) => state.lbFemaleServicePageCount,
    lbPackagePageCount: state => state.lbPackagePageCount,
    lbpWaxingPageCount: state => state.lbpWaxingPageCount,
    lbpLighteningPageCount: state => state.lbpLighteningPageCount,
    lbpScrubPageCount: state => state.lbpScrubPageCount,
    pnServicePageCount: state => state.pnServicePageCount
}

const mutations = {
    setServices: (state, services) => state.services = services,
    setMoreServices: (state, payload) => state.services = [...state.services, ...payload],
    setPackages: (state, packages) => state.packages = packages,
    setBusinessUnit: (state, val) => (state.businessUnit = val),

    setFemaleServices: (state, payload) => state.femaleServices = payload,
    setMaleServices: (state, payload) => state.maleServices = payload,
    setMoreMaleServices: (state, payload) => state.maleServices = [...state.maleServices, ...payload],
    setMoreFemaleServices: (state, payload) => state.femaleServices = [...state.femaleServices, ...payload],
    setMorePackages: (state, payload) => state.packages = [...state.packages, ...payload],
    setWaxingServices: (state, payload) => state.waxingServices = payload,
    setLighteningServices: (state, payload) => state.lighteningServices = payload,
    setMoreWaxingServices: (state, payload) => state.waxingServices = [...state.waxingServices, ...payload],
    setMoreLighteningServices: (state, payload) => state.lighteningServices = [...state.lighteningServices, ...payload]
}

const actions = {
    async fetchServices({ state, commit }, config) {
        const services = await this.$api.services.get(config)

        const { data } = services;
        
        if (config.only_gender === 'male' && 'page' in config && config.page > 1) {
            commit('setMoreMaleServices', data.data);
            return;
        }

        if (config.only_gender === 'female' && 'page' in config && config.page > 1) {
            commit('setMoreFemaleServices', data.data);
            return;
        }

        if (config.only_gender === 'female') {
            state.lbFemaleServicePageCount = data.last_page;
            commit('setFemaleServices', data.data);
            return;
        } else if(config.only_gender === 'male') {
            state.lbMaleServicePageCount = data.last_page;
            commit('setMaleServices', data.data)
            return;
        }

        if ('category_id' in config && config.category_id === 8 && config.page > 1) {
            commit("setMoreWaxingServices", data.data);
            return;
        }

        if ('category_id' in config && config.category_id === 7 && config.page > 1) {
            commit("setMoreLighteningServices", data.data);
            return;
        }

        if ('category_id' in config && config.category_id === 8) {
            state.lbpWaxingPageCount = data.last_page;
            commit("setWaxingServices", data.data);
            return;
        }

        if ('category_id' in config && config.category_id === 7) {
            state.lbpLighteningPageCount = data.last_page;
            commit("setLighteningServices", data.data);
            return;
        }

        if ('business_unit_id' in config && config.page > 1) {
            commit('setMoreServices', data.data)
            return
        }

        if ('business_unit_id' in config) {
            if (config.business_unit_id === 4) state.pnServicePageCount = data.last_page;

            commit('setServices', data.data)
            return
        }
    },
    async fetchPackages({ state, commit }, config) {
        const services = await this.$api.packages.get(config)
        const { data } = services;

        if ('page' in config) {
            commit('setMorePackages', data.data);
            return;
        }
        
        if (config.business_unit_id === 3) {
            state.lbpScrubPageCount = data.last_page;
        }

        if (config.business_unit_id === 1) {
            state.lbPackagePageCount = data.last_page;
        }
        
        commit('setPackages', data.data);
    },

    async fetchBusinessUnit({ commit }, config) {
        const businessUnit = await this.$api.businessUnit.fetch(config)
        const data = businessUnit.data.data

        commit('setBusinessUnit', data)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}