import Vuex from 'vuex'
import Vue from 'vue'
import storePlugins from '@/plugins/storePlugins'
Vue.use(Vuex)
import location from './modules/location'
import services from './modules/services'
import home from './modules/home'
import products from './modules/products'
import referral from './modules/referral'

export default new Vuex.Store({
    plugins: [storePlugins],
    modules: {
        location,
        services,
        home,
        products,
        referral
    },
})