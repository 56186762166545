const state = {
    branches: [],
    filteredBranchesByName: {},
    branchName: [],
    error: [],
    regionsContainer: [],
    sentRegionID: [], 
}

const getters = {
    allBranch: (state) => state.branches,
    branchesByName: (state) => {
        switch (state.filteredBranchesByName.addressCount) {
            case 0:
            case undefined:
                return state.branches.data
            case 1:
                return state.branches.data.filter((branch) => {
                    for (let key in state.filteredBranchesByName) {
                        if (branch[key]) {
                            if (
                                branch[key]
                                .toLowerCase()
                                .includes(state.filteredBranchesByName[key])
                            )
                                return true
                        }
                    }
                    return false
                })
        }
    },
    error: (state) => state.error,
    regions: (state) => state.regionsContainer,
    sentRegionID: (state) => state.sentRegionID,
}

const mutations = {
    setBranches: (state, branches) => (state.branches = branches),
    setSearchBranchName: (state, val) => (state.filteredBranchesByName = val),
    setBranchName: (state, val) => (state.branchName = val),
    setError: (state, val) => (state.error = val),
    setRegions: (state, payload) => (state.regionsContainer = payload),
    setSentRegion: (state, regionId) => (state.sentRegionID = regionId),
}

const actions = {
    async fetchBranches({ commit }, config) {
        const branches = await this.$api.branches.fetch(config)
        const { data, error } = branches
        if (data) {
            commit('setBranches', data.data);
        } else {
            commit('setError', error)
        }
    },
    fetchBranchByName({ commit }, query) {
        commit('setSearchBranchName', query)
    },
    async getRegions({ commit }) {
        const regions = await this.$api.regions.get();
        const { data } = regions;
        commit('setRegions', data);
    },
    setSentRegion({ commit }, regionId) {
        commit('setSentRegion', regionId);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
